import { createTheme } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#2b2b2b',
        },
        secondary: {
            main: '#e27d00',
            contrastText: '#fff'
        },
        background: {
            default: '#f5f5f5',
        },
    },
    typography: {
        fontSize: 15,
        fontWeightLight: 300,
        h1: {
            fontSize: '2.4rem',
        },
        h2: {
            fontSize: '2.1rem',
        },
        h3: {
            fontSize: '2rem',
        },
        h4: {
            fontSize: '1.8rem',
        },
        subtitle1: {
            fontSize: '1.15rem',
            fontWeight: 500,
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '1.3rem',
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    background: '#e27d00'
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: '#2b2b2b',
                    // Some CSS
                    "&.Mui-selected": {
                        color: '#fff',
                    }
                },

            },
        }
    },
    shape: {
        borderRadius: 10,
    },
    props: {
        MuiAppBar: {
            color: 'transparent',
        },
    },
};
const lightTheme = createTheme(themeOptions);

export default lightTheme;
