import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";

const putSubscribed = async (subscribed: string): Promise<boolean> => {
  var config: AxiosRequestConfig = {
    method: "PUT",
    url: process.env.REACT_APP_API_DOMAIN + "/api/user/subscribed",
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
    data: {
      subscribed: subscribed,
    },
  };

  const response = await axios(config);
  return response.data["hydra:member"].pop();
};
export default putSubscribed;
