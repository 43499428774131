import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const SimpleBottomNavigation = (props: {
  navigationIndex: (value: string) => void;
}) => {
  const [value, setValue] = React.useState("messaggi");
  const router = useNavigate();
  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        switch (newValue) {
          case "home":
            router("/");
            break;
          case "rapporti":
            router("/rapporti");
            break;
          default:
            setValue(newValue);
            props.navigationIndex(newValue);
            break;
        }
      }}
    >
      <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
      <BottomNavigationAction
        label="Rapporti"
        value="rapporti"
        icon={<AccountCircleIcon />}
      />
    </BottomNavigation>
  );
};
export default SimpleBottomNavigation;
