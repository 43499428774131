import React from "react";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Typography,
  Divider,
  Avatar,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import getProfilo from "../../api/getProfilo";
import putPassword from "../../api/putPassword";
import putUtente from "../../api/putUtente";
import ButtonAppBar from "../bar";
import { ApiResponseProfiloType } from "../../types/types";
export default function Profilo() {
  const [profilo, setProfilo] = React.useState<ApiResponseProfiloType>();
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [nome, setNome] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [cognome, setCognome] = React.useState("");

  const fetch = async () => {
    const dati_profilo = await getProfilo();
    setProfilo(dati_profilo);
  };
  React.useEffect(() => {
    fetch();
  }, []);
  const _updatePassword = async () => {
    const res = await putPassword(password);
    setOpen(true);
  };
  const _updateUtente = async () => {
    const res = await putUtente({
      nome: nome,
      cognome: cognome,
      mobile: telefono,
    });
    setOpen(true);
  };
  return (
    <React.Fragment>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(!open)}
        message="aggiornamento effettuato"
      />
      <ButtonAppBar />
      <Box mt={7} />
      <Box display="flex" p={4} alignItems="center" justifyContent="center">
        <Typography align="center">
          <Avatar>
            {profilo?.nome ? profilo.nome.charAt(0).toUpperCase() : ""}
            {profilo?.cognome ? profilo.cognome.charAt(0).toUpperCase() : ""}
          </Avatar>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6">
          {/* TODO  centrare nome  cognome*/}
          {profilo?.nome} {profilo?.cognome}
          <br />
          {profilo?.mobile}
        </Typography>
      </Box>
      <Box display="flex" mb={2} alignItems="center" justifyContent="center">
        <Typography variant="subtitle2">{profilo?.email}</Typography>
      </Box>
      <Divider />
      <Box p={3}>
        <Box m={1} />
        <TextField
          label="Nome"
          type="text"
          sx={{ width: "100%" }}
          onChange={(ev) => setNome(ev.target.value)}
        />
        <Box p={1} />
        <TextField
          label="Cognome"
          type="text"
          sx={{ width: "100%" }}
          onChange={(ev) => setCognome(ev.target.value)}
        />
        <Box p={1} />
        <TextField
          label="Telefono"
          type="text"
          sx={{ width: "100%" }}
          onChange={(ev) => setTelefono(ev.target.value)}
        />
        <Box p={2}>
          <Button
            color="secondary"
            variant="contained"
            sx={{ width: "100%" }}
            onClick={_updateUtente}
          >
            aggiorna
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h6">
          <ChangeCircleIcon /> Reimposta la password
        </Typography>
        <Box m={1} />
        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          sx={{ width: "100%" }}
          autoComplete="current-password"
          onChange={(ev) => setPassword(ev.target.value)}
        />
        <Box p={2}>
          <Button
            color="secondary"
            variant="contained"
            sx={{ width: "100%" }}
            onClick={_updatePassword}
          >
            aggiorna
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
