import React from "react";
import { Typography, Box, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SimpleBottomNavigation from "../simple_bottom_navigation";
export default function Contattaci() {
  const router = useNavigate();
  const params = useParams();
  return (
    <React.Fragment>
      <Box p={2}>
        <Typography color="text.primary" variant="h4">
          Contatti
        </Typography>
      </Box>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <SimpleBottomNavigation navigationIndex={router} />
      </Paper>
    </React.Fragment>
  );
}
