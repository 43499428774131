import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";
import { ApiResponseUserIsSubscribedType } from "../types/types";

const getSubscribed = async (
  id_user: number
): Promise<ApiResponseUserIsSubscribedType> => {
  var config: AxiosRequestConfig = {
    method: "GET",
    url:
      process.env.REACT_APP_API_DOMAIN + "/api/user/is-subscribed/" + id_user,
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
    data: {},
  };

  const response = await axios(config);
  return response.data;
};
export default getSubscribed;
