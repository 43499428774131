export const setStorageItem = (key: string, value: any) => {
  localStorage.setItem(key, value);
};
export const getStorageItem = (key: string): any => {
  return localStorage.getItem(key);
};
export const removeStorage = (key: string) => {
  localStorage.removeItem(key);
};
export const clearStorage = () => {
  localStorage.clear();
};
export const hasStorageItem = (key: string) => {
  return localStorage.hasOwnProperty(key);
};
export const getStorageLength = () => {
  return localStorage.length;
};
