import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";

const postRichiestaAppuntamento = async (
  messaggio: string
): Promise<boolean> => {
  var config: AxiosRequestConfig = {
    method: "post",
    url: process.env.REACT_APP_API_DOMAIN + "/api/richiesta-appuntamento",
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
    data: messaggio,
  };

  const response = await axios(config);
  return response.data;
};
export default postRichiestaAppuntamento;
