import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useNavigate } from "react-router-dom";
import useAuth from "../lib/use_auth";
import { ListItemButton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

export default function TemporaryDrawer(props: {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
}) {
  const router = useNavigate();
  const auth = useAuth();
  const list = () => (
    <Box
      onClick={(e) => {
        props.toggleDrawer(false);
      }}
      onKeyDown={(e) => {
        props.toggleDrawer(false);
      }}
    >
      <Box mb={7}></Box>
      <List>
        <ListItemButton onClick={() => router("/")}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => router("/profilo")}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Profilo"} />
        </ListItemButton>
        <Divider />

        <ListItemButton
          onClick={() => {
            router("/rapporti");
          }}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Rapporti"} />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            router("/help");
          }}
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={"Aiuto"} />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => {
            auth.signout();
          }}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Esci"} />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <React.Fragment key={"right"}>
      <Drawer
        anchor={"right"}
        open={props.open}
        onClose={() => props.toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </React.Fragment>
  );
}
