import React from "react";
import doLogin from "../api/doLogin";
import { getToken, removeToken } from "./token";
import { useNavigate } from "react-router-dom";
import { removeStorage } from "./storage";
import { EXTERNAL_USER_ID } from "./constants";
interface IAuthPovider {
  signin: (data: FormData) => Promise<void>;
  signout: () => void;
  checkIsSigned: () => boolean;
  user: string | null;
}
const AuthContext = React.createContext({} as IAuthPovider);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<string | null>(getToken());
  const navigate = useNavigate();
  const signin = async (data: FormData) => {
    const res = await doLogin(
      data.get("email") as string,
      data.get("password") as string
    );
    if (res === true) {
      setUser(getToken());
      navigate("/");
    }
  };

  const signout = () => {
    removeToken();
    removeStorage(EXTERNAL_USER_ID);
    setUser(null);
    navigate("/login");
  };
  const checkIsSigned = () => {
    return user ? true : false;
  };
  const value = { user, signin, signout, checkIsSigned };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default function useAuth(): IAuthPovider {
  return React.useContext(AuthContext);
}
