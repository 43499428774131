import { set, remove, get } from "local-storage";

const setToken = (token: string): boolean => set("unicom", token);

const removeToken = (): void => remove("unicom");

const hasToken = (): boolean => get("unicom") !== null;

const getToken = (): string => get("unicom");

export { getToken, setToken, removeToken, hasToken };
