import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Societa from "./components/screens/societa";
import Home from "./components/screens/home";
import Profilo from "./components/screens/profilo";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./styles/theme/lightTheme";
import BasicPage from "./components/basic_page";
import { isDesktop, isTablet } from "react-device-detect";
import BasicScreen from "./components/basic_screen";
import RequireAuth from "./components/require_auth";
import { AuthProvider } from "./lib/use_auth";
import ResetPassword from "./pages/reset_passwrod";
import Contattaci from "./components/screens/contattaci";
import Appuntamento from "./components/screens/appuntamento";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import HelpScreen from "./components/screens/helpscreen";

ReactDOM.render(
  <React.StrictMode>
    {isDesktop || isTablet ? (
      <Container component="main" maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Typography variant="h2" textAlign={"center"}>
            spiacente ma questa applicazione è accessibile solo da mobile
          </Typography>
        </Box>
      </Container>
    ) : (
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <BasicScreen>
                      <Home />
                    </BasicScreen>
                  </RequireAuth>
                }
              />
              <Route
                path="/rapporti"
                element={
                  <RequireAuth>
                    <BasicScreen>
                      <Societa />
                    </BasicScreen>
                  </RequireAuth>
                }
              />
              <Route
                path="/help"
                element={
                  <RequireAuth>
                    <BasicScreen>
                      <HelpScreen />
                    </BasicScreen>
                  </RequireAuth>
                }
              />
              <Route
                path="/appuntamento"
                element={
                  <RequireAuth>
                    <BasicScreen>
                      <Appuntamento />
                    </BasicScreen>
                  </RequireAuth>
                }
              />
              <Route
                path="/messaggi/:societa/:societa_id"
                element={
                  <RequireAuth>
                    <BasicScreen>
                      <BasicPage />
                    </BasicScreen>
                  </RequireAuth>
                }
              />
              <Route
                path="/profilo"
                element={
                  <RequireAuth>
                    <BasicScreen>
                      <Profilo />
                    </BasicScreen>
                  </RequireAuth>
                }
              />
              <Route
                path="/contattaci"
                element={
                  <RequireAuth>
                    <BasicScreen>
                      <Contattaci />
                    </BasicScreen>
                  </RequireAuth>
                }
              />
              <Route path="/login" element={<App />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
