import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

import SimpleBottomNavigation from './simple_bottom_navigation';
import LabTabs from './tab_screen';

export default function BasicPage() {
    const [screen, setScreen] = React.useState('messaggi')

    return (
        <Box sx={{ pb: 7 }}>
            <CssBaseline />
            <LabTabs />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} >
                <SimpleBottomNavigation navigationIndex={setScreen} />
            </Paper>
        </Box>
    );
}

