import React, { useRef } from "react";
import { Typography, Box, Button, Alert, AlertColor } from "@mui/material";
import TextField from "@mui/material/TextField";
import postRichiestaAppuntamento from "../../api/postRichiestaAppuntamento";
import Snackbar from "@mui/material/Snackbar";

export default function Appuntamento() {
  const msgRef = useRef<HTMLInputElement>();
  const [open, setOpen] = React.useState(false);
  const [messaggio, setMessaggio] = React.useState(
    "Richiesta inviata con successo"
  );
  const [alertType, setAlertType] = React.useState<AlertColor>("success");
  const [duration, setDuration] = React.useState(5000);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box p={2}>
        <Typography color="text.primary" variant="h4">
          Richiedi un appuntamento
        </Typography>
      </Box>
      <Box p={2}>
        <Typography color="text.primary" variant="body1">
          Se necessiti incontrarci, invia la tua richiesta e ti contatteremo il
          prima possibile per fissare l'incontro. <br />
          Grazie.
        </Typography>
      </Box>

      <Box p={1}>
        <TextField
          inputRef={msgRef}
          placeholder="Inserisci la tua richiesta"
          multiline
          fullWidth
          rows={4}
        />
      </Box>
      <Box p={2}>
        <Button
          color="secondary"
          variant="contained"
          sx={{ width: "100%" }}
          onClick={() => {
            if (msgRef.current?.value) {
              postRichiestaAppuntamento(msgRef.current?.value).then((data) => {
                if (false === data) {
                  setMessaggio("Errore nell'invio della richiesta");
                  setAlertType("error");
                } else {
                  setMessaggio("Richiesta inviata con successo");
                  setAlertType("success");
                }
                setOpen(true);
              });
            } else {
              setMessaggio("E' necessario inserire un messaggio");
              setAlertType("warning");
              setOpen(true);
            }
          }}
        >
          invia
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {messaggio}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
