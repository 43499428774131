import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";
import { ApiResponseProfiloType } from "../types/types";

const getProfilo = async (): Promise<ApiResponseProfiloType> => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: process.env.REACT_APP_API_DOMAIN + "/api/myuser",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  };
  const response = await axios(config);
  return response.data;
};
export default getProfilo;
