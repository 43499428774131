import axios, { AxiosRequestConfig } from "axios";
import { setToken } from "../lib/token";
const doResetPassword = async (username: string): Promise<boolean> => {
  var data = JSON.stringify({
    username: username,
  });
  var config: AxiosRequestConfig = {
    method: "post",
    url: process.env.REACT_APP_API_DOMAIN + "/api/reset-password",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  return true;
};
export default doResetPassword;
