import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  default as ExpandMore,
  default as ExpandMoreIcon,
} from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const HelpScreen = () => {
  return (
    <Grid gap={2}>
      <HelpDetail title="Posseggo un Android">
        E' necessario attivare le notifiche push per ricevere le notifiche
        <br />
        Per attivare le notifiche push è necessario:
        <ul>
          <li>Andare nelle impostazioni del telefono</li>
          <li>Selezionare l'applicazione</li>
          <li>Selezionare le notifiche</li>
          <li>Selezionare il canale "Notifiche push"</li>
          <li>Selezionare "Abilita"</li>
        </ul>
        <Box mb={3}>
          Per maggiori informazioni guarda il video su{" "}
          <Link href="https://youtu.be/tanj4J_slK4">come installare</Link>.
        </Box>
        <Box>
          Guarda anche il video su {""}
          <Link href="https://youtu.be/tanj4J_slK4">
            come atttivare le notifiche push
          </Link>
          .
        </Box>
      </HelpDetail>
      <HelpDetail title="Posseggo un IPhone">ciao</HelpDetail>
    </Grid>
  );
};

export default HelpScreen;

interface IHelpDetailProps {
  title: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
}

const HelpDetail = ({ title, children }: IHelpDetailProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };
  return (
    <Grid p={1}>
      <Card>
        <CardActions style={{ background: "#f1f1f1" }}>
          <List>
            <ListItem>
              <ListItemText primary={title} />
            </ListItem>
          </List>
          <IconButton onClick={handleExpandClick}>
            <ExpandMore>
              <ExpandMoreIcon />
            </ExpandMore>
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>{children}</CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
};
