import React from "react";
import { isAndroid } from "react-device-detect";
import OneSignal from "react-onesignal";
import getProfilo from "../api/getProfilo";
import putMessaggioVisto from "../api/putMessaggioVisto";
import putSubscribed from "../api/putSubscribed";
import { EXTERNAL_USER_ID } from "../lib/constants";
import { getStorageItem, setStorageItem } from "../lib/storage";
import useAuth from "../lib/use_auth";
const HocOneSignal = (props: { children: React.ReactNode | JSX.Element }) => {
  const auth = useAuth();
  React.useEffect(() => {
    if (isAndroid) {
      window.OneSignal = window.OneSignal || [];
      OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL as string,
        subdomainName: process.env.REACT_APP_TESTLABEL,
        promptOptions: {
          slidedown: {
            enabled: true,
            actionMessage:
              "Per ricevere le notifiche, clicca su 'Permetti' e poi su 'Abilita'",
            acceptButtonText: "Abilita",
            cancelButtonText: "No grazie",
            // categories: {
            //   tags: [
            //     {
            //       tag: "react",
            //       label: "ReactJS",
            //     },
            //     {
            //       tag: "angular",
            //       label: "Angular",
            //     },
            //     {
            //       tag: "vue",
            //       label: "VueJS",
            //     },
            //     {
            //       tag: "js",
            //       label: "JavaScript",
            //     },
            //   ],
            // },
          },
        },
        welcomeNotification: {
          title: "App Unicom",
          message: "Ora hai attivato l'app. Grazie",
        },
      }).then(() => {
        OneSignal.showNativePrompt();
        if (
          true === auth.checkIsSigned() &&
          !getStorageItem(EXTERNAL_USER_ID)
        ) {
          getProfilo()
            .then((profilo) => {
              OneSignal.setExternalUserId(profilo.external_user_id);
              setStorageItem(EXTERNAL_USER_ID, profilo.external_user_id);
            })
            .catch((error) => {
              console.log("errore set external user id", error);
            });
        }
      });

      OneSignal.on("subscriptionChange", (isSubscribed) => {
        putSubscribed(isSubscribed);
      });
      OneSignal.on("notificationDisplay", function (event) {
        console.warn("OneSignal notification displayed:", event);
      });
    }
  }, []);
  return <>{props.children}</>;
};

export default HocOneSignal;
