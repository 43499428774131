import { Box } from "@mui/system";
import React, { useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import getProfilo from "../api/getProfilo";
import getSubscribed from "../api/getSubscribed";
import putInstalled from "../api/putInstalled";
import { isPwaInstalled } from "../lib/is_pwa_installed";
import { ApiResponseProfiloType } from "../types/types";
import Analytics from "./analytics";
import ButtonAppBar from "./bar";
import InstallazioneAndroid from "./installazione_android";
import InstallazioneIos from "./installazione_ios";
import OneSignal from "react-onesignal";
import HocOneSignal from "./hoc_onesignal";
export default function BasicScreen(props: { children: React.ReactNode }) {
  const router = useNavigate();
  const [subscribed, setSubscribed] = useState(false);
  React.useEffect(() => {
    if (true === isPwaInstalled()) {
      putInstalled(true);
    }
    getProfilo().then((data) => {
      getSubscribed(data.id).then((response) => {
        setSubscribed(
          isIOS ? response.optin_whatsapp : response.optin_push_notification
        );
      });
    });
  }, []);
  if (
    isIOS &&
    false === subscribed &&
    "show" === process.env.REACT_APP_INSTALL_INFO
  ) {
    return (
      <React.Fragment>
        <InstallazioneIos onlyOptin={true} />
      </React.Fragment>
    );
  }
  if (isAndroid) {
    if (
      (false === subscribed || "granted" !== Notification.permission) &&
      "show" === process.env.REACT_APP_INSTALL_INFO
    ) {
      return (
        <React.Fragment>
          <HocOneSignal>
            <InstallazioneAndroid onlyOptin={true} />
          </HocOneSignal>
        </React.Fragment>
      );
    }
  }
  return (
    <React.Fragment>
      <ButtonAppBar />
      <Box mt={10} />
      <Analytics>{props.children}</Analytics>
    </React.Fragment>
  );
}
