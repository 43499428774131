import axios, { AxiosRequestConfig } from "axios";
import { setToken } from "../lib/token";
const doLogin = async (
  username: string,
  password: string
): Promise<boolean> => {
  var data = JSON.stringify({
    username: username,
    password: password,
  });
  var config: AxiosRequestConfig = {
    method: "post",
    url: process.env.REACT_APP_API_DOMAIN + "/api/login_check",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios(config);
  setToken(response.data["token"]);
  return true;
};
export default doLogin;
