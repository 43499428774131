import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";
import { ApiResponseDocumentType } from "../types/types";
const getDocumenti = async (
  societa_id: number | string
): Promise<ApiResponseDocumentType> => {
  var config: AxiosRequestConfig = {
    method: "get",
    url:
      process.env.REACT_APP_API_DOMAIN +
      "/api/societa/" +
      societa_id +
      "/documenti",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  };

  const response = await axios(config);
  return response.data;
};
export default getDocumenti;
