import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import lightTheme from "../styles/theme/lightTheme";

import useAuth from "../lib/use_auth";
import { useNavigate } from "react-router-dom";
import {
  isAndroid,
  isIOS13,
  isIOS,
  isIPhone13,
  isIPad13,
  isIPod13,
  isMacOs,
  isSafari,
} from "react-device-detect";
import putDeviceType from "../api/putDeviceType";
import InstallazioneIos from "../components/installazione_ios";
import Analytics from "../components/analytics";
import InstallazioneAndroid from "../components/installazione_android";
import { isPwaInstalled } from "../lib/is_pwa_installed";
import HocOneSignal from "../components/hoc_onesignal";

const SignIn = () => {
  const [openSnack, setOpenSnack] = React.useState(false);
  const auth = useAuth();

  const navigate = useNavigate();

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await auth.signin(data);
    } catch (error) {
      setOpenSnack(true);
    }
  };
  if (true === auth.checkIsSigned()) {
    if (
      isIOS13 ||
      isIOS ||
      isIPhone13 ||
      isIPad13 ||
      isIPod13 ||
      isMacOs ||
      isSafari
    ) {
      putDeviceType("ios");
    } else if (isAndroid) {
      putDeviceType("android");
    } else {
      putDeviceType("android");
    }
    navigate("/");
  }
  if (
    isAndroid &&
    isPwaInstalled() === false &&
    "show" === process.env.REACT_APP_INSTALL_INFO
  ) {
    return (
      <HocOneSignal>
        <InstallazioneAndroid />
      </HocOneSignal>
    );
  }
  if (
    isIOS &&
    isPwaInstalled() === false &&
    "show" === process.env.REACT_APP_INSTALL_INFO
  ) {
    return <InstallazioneIos />;
  }
  return (
    <HocOneSignal>
      <ThemeProvider theme={lightTheme}>
        <Analytics>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Snackbar
              open={openSnack}
              autoHideDuration={6000}
              onClose={() => setOpenSnack(!openSnack)}
              message="password non valida"
            />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src="/icon-192x192.png" style={{ width: "80px" }} />
              <Box mt={4} />
              <Typography variant="h3">Login</Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="La tua email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => navigate("/reset-password")}
                >
                  Hai dimenticato la password?
                </Link>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Entra
                </Button>
              </Box>
            </Box>
            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
          </Container>
        </Analytics>
      </ThemeProvider>
    </HocOneSignal>
  );
};
export default SignIn;
