import React from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { isIOS } from "react-device-detect";
import { green } from "@mui/material/colors";
export default function Home() {
  const router = useNavigate();

  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row" justifyContent={"center"}>
        <Box
          m={2}
          component="img"
          sx={{
            width: 110,
          }}
          alt="Unicom stp"
          src="/logo-commercialisti.jpg"
        />

        <Box
          m={3}
          component="img"
          sx={{
            width: 70,
          }}
          alt="Unicom stp"
          src="/icon-192x192.png"
        />
      </Grid>
      <Box textAlign={"center"}>
        <Typography variant="h2" alignContent={"center"}>
          UNICOM STP srl
        </Typography>
        <Typography variant="body1" alignContent={"center"}>
          Dottori Commercialisti
        </Typography>
      </Box>
      <Box alignItems={"center"} display="flex" justifyContent={"center"} m={2}>
        <Box
          component="img"
          sx={{
            width: 250,
          }}
          alt="Unicom stp"
          src="/map.jpg"
          onClick={() =>
            window.open(
              "https://www.google.it/maps/place/Uni-Com+Stp+dottori+commercialisti/@45.2291441,7.6056888,17z/data=!4m5!3m4!1s0x478867a7829a238f:0xf9691e17e80487c6!8m2!3d45.2291441!4d7.6078828"
            )
          }
        />
      </Box>
      {isIOS && (
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems="center"
          spacing={2}
          my={2}
        >
          <IconButton
            aria-label="contatti"
            color="primary"
            onClick={() =>
              window.open(
                "https://www.gupshup.io/whatsapp/optin?bId=004577f6-af3c-4a6f-ade5-f4998d3fe6e4&bName=unicomapp&s=URL&lang=it"
              )
            }
          >
            Iscriviti{" "}
            <Box ml={1}>
              <WhatsAppIcon sx={{ color: green["A400"] }} />
            </Box>
          </IconButton>
        </Grid>
      )}
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <IconButton
              aria-label="rapporto"
              color="primary"
              onClick={() => router("/rapporti")}
            >
              <AccountCircleIcon fontSize="large" />
            </IconButton>
            rapporto
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <IconButton
              aria-label="contatti"
              color="primary"
              onClick={() => window.open("https://www.unicomstp.it/#contatti")}
            >
              <EmailIcon fontSize="large" />
            </IconButton>
            contatti
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <IconButton
              aria-label="appuntamento"
              color="primary"
              onClick={() => router("appuntamento")}
            >
              <PeopleAltIcon fontSize="large" />
            </IconButton>
            appuntamento
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <IconButton
              aria-label="news"
              color="primary"
              onClick={() => window.open("https://unicomstp.it/category/news/")}
            >
              <NewspaperIcon fontSize="large" />
            </IconButton>
            news
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
