import React from "react";
import ButtonAppBar from "../bar";
import {
  Box,
  Card,
  CardContent,
  List,
  ListItemButton,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import getSocieta from "../../api/getSocieta";
import { useNavigate } from "react-router-dom";
import { SocietaType } from "../../types/types";
export default function Societa() {
  const router = useNavigate();

  const [listaSocieta, setListaSocieta] = React.useState<Array<SocietaType>>(
    []
  );
  const [loading, setLoading] = React.useState(true);

  const _fetchSocieta = async () => {
    try {
      const lista = await getSocieta();
      setListaSocieta([...lista["hydra:member"]]);
    } catch (error) {}
    setLoading(false);
  };
  React.useEffect(() => {
    _fetchSocieta();
  }, []);
  if (true === loading) {
    return (
      <React.Fragment>
        <ButtonAppBar />
        <Box mt={7} />

        <Typography align="center">
          <CircularProgress />
        </Typography>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Box p={2}>
        <Typography variant="h4">Seleziona un rapporto</Typography>
      </Box>
      <List>
        {listaSocieta.map((societa, index) => {
          return (
            <ListItemButton
              onClick={() => {
                router("/messaggi/" + societa.ditta + "/" + societa.id);
              }}
            >
              <Card sx={{ width: "100%" }}>
                <CardContent>
                  <Typography color="text.primary">
                    {societa.ditta} {societa.privato ? societa.nome : ""}
                  </Typography>
                  <Typography color="text.secondary">
                    cod.fis.: {societa.codfis}
                  </Typography>
                </CardContent>
              </Card>
            </ListItemButton>
          );
        })}
      </List>
    </React.Fragment>
  );
}
