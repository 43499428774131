import React from "react";
import ReactG4 from "react-ga4";
import { InitOptions } from "react-ga4/types/ga4";
import { useLocation } from "react-router-dom";
ReactG4.initialize([
  {
    trackingId: process.env.REACT_APP_GA4 as string,
    //   gaOptions: {...}, // optional
    //   gtagOptions: {...}, // optional
  },
  {
    //   trackingId: "your second GA measurement id",
  } as InitOptions,
]);

export default function Analytics(props: { children: React.ReactNode }) {
  let location = useLocation();

  React.useEffect(() => {
    ReactG4.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return <React.Fragment>{props.children}</React.Fragment>;
}
