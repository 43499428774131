import React from 'react';
import './App.css';
import SignIn from './pages/login';


function App() {
  return (
    <SignIn />
  );
}

export default App;
