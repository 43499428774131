import React, { useRef, useState } from "react";
import List from "@mui/material/List";
import getMessaggi from "../../api/getMessaggi";
import putMessaggioVisto from "../../api/putMessaggioVisto";
import {
  Snackbar,
  Alert,
  Card,
  CardContent,
  Typography,
  Box,
  CardHeader,
  ListItemButton,
  Paper,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import TestoMessaggio from "./testo_messaggio";
import DoneIcon from "@mui/icons-material/Done";
import { MessaggioType } from "../../types/types";

export default function Messaggi() {
  const [messages, setMessages] = React.useState<Array<MessaggioType>>([]);
  const [open, setOpen] = React.useState(false);
  const [openMsg, setOpenMsg] = React.useState(false);
  const [testoMessaggio, setTestoMessaggio] = React.useState<{
    testo: string;
    link: string;
    testoAzione: string;
  }>();
  const [spinner, setSpinner] = React.useState(true);
  const router = useNavigate();
  const params = useParams();

  const fetch = async () => {
    const { societa, societa_id } = params;

    if (!societa_id) {
      setSpinner(false);
      return;
    }
    try {
      const listaMessaggi = await getMessaggi(societa_id);
      if (listaMessaggi["@type"] === "hydra:error") {
        setSpinner(false);
        return;
      }
      setMessages([...listaMessaggi["hydra:member"]]);
    } catch (error) {}
    setSpinner(false);
  };
  React.useEffect(() => {
    fetch();
  }, [router]);
  const refMsg = useRef();
  const sendMessagioVisto = (id: number) => {
    putMessaggioVisto(id);
  };
  if (spinner === true) {
    return (
      <Typography align="center">
        <CircularProgress />
      </Typography>
    );
  }
  return (
    <React.Fragment>
      <TestoMessaggio
        ref={refMsg}
        open={openMsg}
        messaggio={testoMessaggio}
        onClose={setOpenMsg}
      />
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
        message="Messaggio visto"
      />
      <Box p={2} alignItems="center">
        <Typography color="text.primary" variant="h4">
          Ultimi messaggi
        </Typography>
      </Box>
      {messages.length === 0 ? (
        <Typography align="center">
          Nessun messaggio <em>non letto</em> trovato
        </Typography>
      ) : (
        <List>
          {messages.map(
            (
              { data_visione, data_invio, id, push_message_id, ...messaggio },
              index
            ) => {
              return (
                <ListItemButton
                  onClick={() => {
                    sendMessagioVisto(id);
                    setOpen(true);
                    setTestoMessaggio(messaggio);
                    setOpenMsg(true);
                  }}
                  key={index + id}
                >
                  <Paper elevation={4} sx={{ width: "100%" }}>
                    <Box p={2}>
                      <Typography variant="h4">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>{messaggio.titolo}</Box>
                          <Box
                            sx={{ width: "10px" }}
                            alignItems="end"
                            pr={5}
                            color="secondary.main"
                          >
                            {data_visione < "9999-12-31" ? (
                              <DoneIcon sx={{ color: "#388E3C" }} />
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                      </Typography>
                      <Typography variant="body2" p={2}>
                        {data_invio < "9999-12-31"
                          ? "inviato il: " +
                            moment(data_invio).format("DD-MM -YYYY")
                          : ""}
                        <br />
                        {data_visione < "9999-12-31"
                          ? "visto il: " +
                            moment(data_visione).format("DD-MM -YYYY")
                          : ""}
                      </Typography>
                      <Typography align="right">leggi</Typography>
                    </Box>
                  </Paper>
                </ListItemButton>
              );
            }
          )}
        </List>
      )}
    </React.Fragment>
  );
}
