import { Grid } from "@mui/material";
import React from "react";

// import required modules
export const SlideContainer = (props: {
  children: React.ReactNode;
  background_color: string;
}) => {
  return (
    <Grid
      container
      style={{ backgroundColor: props.background_color }}
      minHeight="90vh"
      minWidth={"100vw"}
      direction="column"
      justifyContent={"center"}
      p={2}
    >
      {props.children}
    </Grid>
  );
};
