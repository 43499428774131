import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SnackBar from "@mui/material/Snackbar/Snackbar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import OneSignal from "react-onesignal";
import { useNavigate } from "react-router-dom";
import getProfilo from "../api/getProfilo";
import putMessaggioVisto from "../api/putMessaggioVisto";
import putSubscribed from "../api/putSubscribed";
import { removeToken } from "../lib/token";
import TemporaryDrawer from "./drawer";

export default function ButtonAppBar() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [oneSignInit, setOneSignInit] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const router = useNavigate();
  React.useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL as string,
    }).then(() => {
      setOneSignInit(true);
      OneSignal.on("subscriptionChange", (isSubscribed) => {
        putSubscribed(isSubscribed);
      });
    });
  }, []);
  React.useEffect(() => {
    getProfilo()
      .then((profilo) => {
        OneSignal.setExternalUserId(profilo.external_user_id);
        OneSignal.on("notificationDismiss", function (event) {
          putMessaggioVisto(event.id);
        });
      })
      .catch((error) => {
        if (401 === error.response.status) {
          removeToken();
          setOpenSnack(true);
          router("/");
        }
      });
  }, [oneSignInit]);
  const toggleDrawer = (open: boolean) => setOpenDrawer(open);
  return (
    <React.Fragment>
      <SnackBar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(!openSnack)}
        message="Utente disattivato"
      />
      <AppBar position="fixed">
        <Toolbar>
          <Typography sx={{ flexGrow: 1 }}>
            <Box
              component="img"
              sx={{
                width: 70,
              }}
              alt="Unicom stp"
              src="/icon-192x192.png"
              onClick={() => router("/")}
            />
          </Typography>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
            UNICOM
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <TemporaryDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
    </React.Fragment>
  );
}
