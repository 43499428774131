import { Box, Button, Grid, Link } from "@mui/material";
import React, { useState } from "react";
import IconIosHome from "./icon_ios_home";
import IconIosShare from "./icon_ios_share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { SlideContainer } from "./slide_container";
import { green } from "@mui/material/colors";
import ReactPlayer from "react-player";
const color = "#e6e6e6";

const InstallazioneIos = (props: { onlyOptin?: boolean }) => {
  const font_size = "1.5rem";

  return (
    <Grid
      minHeight={"100vh"}
      minWidth={"100vw"}
      style={{ backgroundColor: color }}
    >
      <Swiper
        modules={[Pagination]}
        pagination
        slidesPerView={1}
        spaceBetween={0}
      >
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                sx={{
                  width: 120,
                }}
                alt="Unicom stp"
                src="/icon-192x192.png"
              />
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box textAlign={"justify"} fontSize={font_size} whiteSpace="normal">
              Per utilizzare UnicomApp è necessario installare sul telefono
              l'app pwa.
            </Box>
            <Box textAlign={"center"} fontSize={font_size}>
              E' molto facile.
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"} fontSize={font_size}>
              <Box component={"p"}>
                Tocca l'icona <IconIosShare /> su Safari
              </Box>
              <Box component={"p"}>
                Poi usa l'icona <IconIosHome /> per installare.{" "}
              </Box>
              <Box component={"p"}>
                Completata l'operazione trovi l'applicazione nella schermata
                iniziale.
              </Box>
              <Box component={"p"}>
                Nella prossima slide trovi un video che ti spiega come fare.
              </Box>
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box justifyContent={"center"} display="flex">
              <ReactPlayer
                url="/ios_install.mp4"
                height={"80%"}
                width={"80%"}
                controls
              />
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"} fontSize={font_size}>
              <Box component={"p"}>
                Infine, per attivare l'app devi attivare i messaggi su whatsapp
              </Box>
              <Box component={"p"} mb={3}>
                <WhatsAppIcon fontSize="large" />
              </Box>
              <Button
                style={{ backgroundColor: green[500] }}
                variant="contained"
                href={
                  "https://www.gupshup.io/whatsapp/optin/?bId=004577f6-af3c-4a6f-ade5-f4998d3fe6e4&bName=unicomapp&s=URL&lang=it"
                }
              >
                clicca qui per ricevere i messaggi whatsapp.
              </Button>
              <Box component={"p"} whiteSpace="normal">
                Dopo aver completato attendi 1 minuto di orologio che
                aggiorniamo il tuo profilo. Poi puoi aggiornare l'app oppure
                uscire e rientrare.
              </Box>
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box justifyContent={"center"} display="flex">
              <ReactPlayer
                url="/ios_wtz.mp4"
                height={"80%"}
                width={"80%"}
                controls
              />
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"}>
              <Box component={"p"} mb={3} fontSize={font_size}>
                Se hai fatto tutto puoi accedere all'app. La trovi sul tuo
                telefono.
              </Box>
              <Box component={"p"} mb={3}>
                <WhatsAppIcon fontSize="large" />
              </Box>
              <Button
                color="error"
                variant="contained"
                href={"https://wa.me/" + process.env.REACT_APP_SUPPORT_MOBILE}
              >
                Se hai dei problemi contattaci
              </Button>
            </Box>
            <Box component={"p"} mb={3} fontSize={font_size}>
              Ora puoi chiudere questa pagina.
            </Box>
          </SlideContainer>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
};

export default InstallazioneIos;
