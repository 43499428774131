import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { v4 as uuid } from "uuid";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { SlideContainer } from "./slide_container";
import { green } from "@mui/material/colors";
import { isAndroid } from "react-device-detect";
import OneSignal from "react-onesignal";
const color = "#e6e6e6";

const InstallazioneAndroid = (props: { onlyOptin?: boolean }) => {
  const [notificationPermission, setNotificationPermission] = useState(false);
  const font_size = "1.5rem";
  useEffect(() => {
    setNotificationPermission(Notification.permission === "granted");
  }, []);

  return (
    <Grid
      minHeight={"100vh"}
      minWidth={"100vw"}
      style={{ backgroundColor: color }}
    >
      <Swiper
        modules={[Pagination]}
        pagination
        slidesPerView={1}
        spaceBetween={0}
      >
        <SwiperSlide key={uuid()}>
          <SlideContainer background_color={color}>
            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                sx={{
                  width: 120,
                }}
                alt="Unicom stp"
                src="/icon-192x192.png"
              />
            </Box>
          </SlideContainer>
        </SwiperSlide>
        {!props.onlyOptin && (
          <React.Fragment>
            <SwiperSlide key={uuid()}>
              <SlideContainer background_color={color}>
                <Box
                  component="p"
                  textAlign={"center"}
                  fontSize={font_size}
                  whiteSpace="normal"
                >
                  Per utilizzare UnicomApp è necessario installare sul telefono
                  l'app pwa.
                </Box>
                <Box component="p" textAlign={"center"} fontSize={font_size}>
                  E' molto facile.
                </Box>
              </SlideContainer>
            </SwiperSlide>
            <SwiperSlide key={uuid()}>
              <SlideContainer background_color={color}>
                <Box textAlign={"center"} fontSize={font_size}>
                  <Box component={"p"}>
                    Tocca <MoreVertIcon /> sul browser, in alto a destra.
                  </Box>
                  <Box component={"p"}>poi seleziona la voce: </Box>
                  <Box component={"p"}>
                    "Aggiungi a schermata iniziale" oppure "Installa l'app".
                  </Box>
                  <Box component={"p"}>
                    Completata l'operazione trovi l'applicazione nella schermata
                    iniziale.
                  </Box>
                  <Box component={"p"}>
                    Nella prossima slide trovi un video che ti spiega come fare.
                  </Box>
                  <Box
                    component="img"
                    sx={{
                      width: 200,
                    }}
                    alt="Unicom stp"
                    src="/android_screenshot.png"
                  />
                </Box>
              </SlideContainer>
            </SwiperSlide>
            <SwiperSlide key={uuid()}>
              <SlideContainer background_color={color}>
                <Box justifyContent={"center"} display="flex">
                  <ReactPlayer
                    url="/android_install.mp4"
                    height={"80%"}
                    width={"80%"}
                    controls
                  />
                </Box>
              </SlideContainer>
            </SwiperSlide>
          </React.Fragment>
        )}
        {!notificationPermission && (
          <React.Fragment>
            <SwiperSlide key={uuid()}>
              <SlideContainer background_color={color}>
                <Box textAlign={"center"} fontSize={font_size}>
                  <Box component={"p"} whiteSpace="normal">
                    Per attivare UnicomApp devi anche accettare le notifiche.
                  </Box>
                  <Box component={"p"} whiteSpace="normal">
                    E' molto facile.
                  </Box>
                  <Box component={"p"} whiteSpace="normal">
                    Guarda il video nella prossima slide.
                  </Box>
                  <Box component={"p"} whiteSpace="normal">
                    Dopo aver completato attendi 1 minuto di orologio che
                    aggiorniamo il tuo profilo. Poi puoi aggiornare l'app oppure
                    uscire e rientrare.
                  </Box>
                </Box>
              </SlideContainer>
            </SwiperSlide>
            {Notification.permission === "denied" && (
              <SwiperSlide>
                <SlideContainer background_color={color}>
                  <Box justifyContent={"center"} display="flex">
                    <ReactPlayer
                      url="/android_reset_messaggi_push.mp4"
                      height={"80%"}
                      width={"80%"}
                      controls
                    />
                  </Box>
                </SlideContainer>
              </SwiperSlide>
            )}
            <SwiperSlide>
              <SlideContainer background_color={color}>
                <Box justifyContent={"center"} display="flex">
                  <ReactPlayer
                    url="/android_messaggi_push.mp4"
                    height={"80%"}
                    width={"80%"}
                    controls
                  />
                </Box>
              </SlideContainer>
            </SwiperSlide>
          </React.Fragment>
        )}{" "}
        <SwiperSlide key={uuid()}>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"} fontSize={font_size}>
              <Box component={"p"}>Attiva anche i messaggi su whatsapp</Box>
              <Box component={"p"} mb={3}>
                <WhatsAppIcon fontSize="large" />
              </Box>
              <Button
                style={{ backgroundColor: green[500] }}
                variant="contained"
                href={
                  "https://www.gupshup.io/whatsapp/optin/?bId=004577f6-af3c-4a6f-ade5-f4998d3fe6e4&bName=unicomapp&s=URL&lang=it"
                }
              >
                clicca qui per ricevere i messaggi whatsapp
              </Button>
              <Box component={"p"} whiteSpace="normal">
                Dopo aver completato attendi 1 minuto di orologio che
                aggiorniamo il tuo profilo. Poi puoi aggiornare l'app oppure
                uscire e rientrare.
              </Box>
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide key={uuid()}>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"} fontSize={font_size}>
              <Box component={"p"} mb={3}>
                Se hai fatto tutto puoi accedere all'app. La trovi sul tuo
                telefono.
              </Box>
              <Box component={"p"} mb={3}>
                <WhatsAppIcon fontSize="large" />
              </Box>
              <Button
                color="error"
                variant="contained"
                href={"https://wa.me/" + process.env.REACT_APP_SUPPORT_MOBILE}
              >
                Se hai dei problemi contattaci
              </Button>
            </Box>
          </SlideContainer>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
};

export default InstallazioneAndroid;
