import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";
import { ApiResponseUserUpdateType } from "../types/types";
const putPassword = async (
  password: string
): Promise<ApiResponseUserUpdateType> => {
  var data = JSON.stringify({
    password: password,
  });

  var config: AxiosRequestConfig = {
    method: "PUT",
    url: process.env.REACT_APP_API_DOMAIN + "/api/myuser/password",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  };

  axios(config);

  const response = await axios(config);
  return response.data["hydra:member"].pop();
};
export default putPassword;
