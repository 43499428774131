import {
  Link,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
} from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function TestoMessaggio(props: {
  onClose: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  ref: MutableRefObject<undefined>;
  messaggio: { testo: string; link: string; testoAzione: string } | undefined;
}) {
  const { onClose, open, messaggio } = props;
  const handleClose = () => {
    onClose(!open);
  };
  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Messaggio
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant="h5">
          <div
            dangerouslySetInnerHTML={{ __html: messaggio?.testo as string }}
          />
        </Typography>

        {messaggio?.link ? (
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              href="#outlined-buttons"
              onClick={() => {
                window.open(messaggio?.link);
              }}
            >
              {messaggio?.testoAzione ? messaggio?.testoAzione : "Leggi tutto"}
            </Button>
          </Box>
        ) : (
          ""
        )}
      </DialogContent>
    </Dialog>
  );
}
