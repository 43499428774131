import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";
const putDeviceType = async (device: string): Promise<boolean> => {
  var config: AxiosRequestConfig = {
    method: "PUT",
    url:
      process.env.REACT_APP_API_DOMAIN +
      "/api/user/device-type?device=" +
      device,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    data: {},
  };

  axios(config);

  const response = await axios(config);
  return response.data["hydra:member"].pop();
};
export default putDeviceType;
