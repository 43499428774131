import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  ListItemButton,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import getDocumenti from "../../api/getDocumenti";
import {
  DocumentoGbType,
  HydraMemberType,
  SocietaType,
} from "../../types/types";
export default function Documenti() {
  const [documenti, setDocumenti] = React.useState<Array<DocumentoGbType>>([]);
  const [spinner, setSpinner] = React.useState(true);
  const router = useNavigate();
  const params = useParams();
  const fetch = async () => {
    const { societa_id } = params;
    if (!societa_id) {
      setSpinner(false);
      return;
    }
    try {
      const listaDocumenti = await getDocumenti(societa_id);
      setDocumenti([...listaDocumenti["hydra:member"]]);
    } catch (error) {}
    setSpinner(false);
  };
  React.useEffect(() => {
    fetch();
  }, [router]);
  if (spinner === true) {
    return (
      <Typography align="center">
        <CircularProgress />
      </Typography>
    );
  }
  return (
    <React.Fragment>
      <Box p={2}>
        <Typography color="text.primary" variant="h4">
          Ultimi documenti non visti
        </Typography>
      </Box>
      <List>
        {documenti.length === 0 ? (
          <Box p={3}>
            <Typography>Tutti i documenti sono stati visti</Typography>
          </Box>
        ) : (
          documenti.map(({ nomeFile, dataUpload }, index) => (
            <ListItemButton
              key={index}
              onClick={() =>
                window.open("https://gbsoftware.cloud/documentale/")
              }
            >
              <Box p={1} sx={{ width: "100%" }}>
                <Card sx={{ width: "100%" }}>
                  <CardContent>
                    <Typography color="text.primary" variant="body1">
                      {nomeFile}
                    </Typography>
                    <Typography color="text.secondary" variant="body1">
                      {moment(dataUpload).format("DD-MM -YYYY")}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </ListItemButton>
          ))
        )}
      </List>
    </React.Fragment>
  );
}
