import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";

const putMessaggioVisto = async (id_messaggio: number): Promise<boolean> => {
  var config: AxiosRequestConfig = {
    method: "PUT",
    url:
      process.env.REACT_APP_API_DOMAIN +
      "/api/messaggio-visto?id=" +
      id_messaggio,
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
    data: {},
  };

  const response = await axios(config);
  return response.data["hydra:member"].pop();
};
export default putMessaggioVisto;
