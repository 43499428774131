import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";
import { ApiResponseSocietaType } from "../types/types";
const getSocieta = async (): Promise<ApiResponseSocietaType> => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: process.env.REACT_APP_API_DOMAIN + "/api/societa",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  };

  const response = await axios(config);
  return response.data;
};
export default getSocieta;
