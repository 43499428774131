import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../lib/token";

const putInstalled = async (installed: boolean): Promise<boolean> => {
  var config: AxiosRequestConfig = {
    method: "PUT",
    url: process.env.REACT_APP_API_DOMAIN + "/api/user/pwa_installed",
    headers: {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
    },
    data: {
      installed: installed,
    },
  };

  const response = await axios(config);
  return response.data["hydra:member"].pop();
};
export default putInstalled;
